import renderOCVFeedbackPanel from './OcvFeedbackPanel';
import type { ShellControl } from '@1js/suiteux-shell-react';
import { ControlIconsDefinitions } from 'owa-control-icons/lib/data/controlIconsRegistration';
import strings from './OcvFeedbackPanel.locstring.json';

import loc from 'owa-localize';
export const owaIconFontFamily = 'controlIcons';
export default function OcvButton() {
    return {
        id: 'feedback_ocv',
        headerButtonRenderData: {
            id: 'feedback_ocv',
            iconFontName: ControlIconsDefinitions.Feedback,
            iconFontFamily: owaIconFontFamily,
            ariaLabel: loc(strings.ocvFeedbackPanelLabel),
            affordanceMenuItemText: loc(strings.ocvFeedbackPanelLabel),
            onClick: renderOCVFeedbackPanel,
        },
    } as ShellControl;
}
